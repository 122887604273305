import React, {FC} from 'react';

import {Helmet} from 'react-helmet';
import {useTranslation} from '@/core/i18n/useTranslation';
import {IArticleNode} from '@/core/dtos/IArticle';
import HeaderSection from './HeaderSection/HeaderSection';
import CategoriesSection from './CategoriesSection/CategoriesSection';
import ProductsSection from './ProductsSection/ProductsSection';
import ApiDocumentationSection from './ApiDocumentationSection/ApiDocumentationSection';

interface IProps {
  sellArticles: IArticleNode[];
  talkArticles: IArticleNode[];
}
const HomePage: FC<IProps> = ({sellArticles, talkArticles}) => {
  const {t} = useTranslation('home');
  const title = t('title');
  const subtitle = t('subtitle');
  return (
    <div>
      <Helmet>
        <meta name='google-site-verification' content='bByusdcvw3DyOg_Tf1m0_0UjJo2mg6HJaNXOEACPONU' />
      </Helmet>
      <HeaderSection title={title} subtitle={subtitle} />
      <CategoriesSection />
      <ProductsSection sellArticles={sellArticles} talkArticles={talkArticles} />
      <ApiDocumentationSection />
    </div>
  );
};

export default HomePage;
