import React, {FC} from 'react';
import {graphql} from 'gatsby';

import {ICommonPageProps} from '@/core/dtos/ICommonPageProps';
import Layout from '@/components/Layout/Layout';
import HomePage from '@/page-components/HomePage/HomePage';
import {IArticleNode} from '@/core/dtos/IArticle';

interface IProps extends ICommonPageProps {
  data: {
    sellArticles: {
      edges: [{node: IArticleNode}];
    };
    talkArticles: {
      edges: [{node: IArticleNode}];
    };
  };
}
const HomeTemplate: FC<IProps> = ({data, pageContext: {url, lang}, location}) => {
  const sellArticles = data.sellArticles.edges.map(({node}) => node);
  const talkArticles = data.talkArticles.edges.map(({node}) => node);
  return (
    <Layout lang={lang} location={location} url='' seoKey='home'>
      <HomePage sellArticles={sellArticles} talkArticles={talkArticles} />
    </Layout>
  );
};

export default HomeTemplate;

export const pageQuery = graphql`
  query ProductArticles($lang: String!) {
    sellArticles: allMarkdownRemark(filter: {frontmatter: {type: {eq: "sell"}, lang: {in: [$lang]}}}) {
      edges {
        node {
          frontmatter {
            description
            group
            id
            order
            lang
            title
            type
            url
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
    talkArticles: allMarkdownRemark(filter: {frontmatter: {type: {eq: "talk"}, lang: {in: [$lang]}}}) {
      edges {
        node {
          frontmatter {
            description
            group
            id
            order
            lang
            title
            type
            url
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
