import React, {FC} from 'react';
import {Container, Row, Col} from '@inperium-corp/core';
import {LanguageLink, Text, appSettings} from '@inperium-corp/gatsby-core';

import {useTranslation} from '@/core/i18n/useTranslation';
import styles from './ApiDocumentationSection.scss';

const ApiDocumentationSection: FC = () => {
  const {t} = useTranslation('home');
  return (
    <section className={styles['api-doc-section']}>
      <Container>
        <Row>
          <Col md={6} className='d-flex align-items-center offset-md-3 p-0'>
            <div>
              <Text tagStyle='h5'>{t('api-section.title')}</Text>
              <LanguageLink to={appSettings.apiDocumentationSiteUrl} colorScheme='blue'>
                <Text tagStyle='p2'>{t('api-section.link-text')}</Text>
              </LanguageLink>
            </div>
            <img src='/images/home/code.png' alt={t('api-section.title')} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ApiDocumentationSection;
