import React, {FC} from 'react';
import {Container, Row, Col} from '@inperium-corp/core';
import {LanguageLink, ResponsiveImg, Text} from '@inperium-corp/gatsby-core';

import {useTranslation} from '@/core/i18n/useTranslation';
import {getCategories, CATEGORIES_TYPE} from '@/core/data/categories';
import styles from './CategoriesSection.scss';

const CategoriesSection: FC = () => {
  const {t} = useTranslation('home');
  const {t: tCategories} = useTranslation('categories');
  const categoriesList = getCategories(tCategories).filter(({type}) =>
    [CATEGORIES_TYPE.ACCOUNT, CATEGORIES_TYPE.SECURITY].includes(type)
  );
  return (
    <section className={styles['categories-section']}>
      <Container>
        <Row>
          {categoriesList.map(({title, description, link, image}) => (
            <Col key={title} md={6} className={styles['categories-section__item']}>
              <div className={styles['categories-section__item-text']}>
                <Text tagStyle='h4'>{title}</Text>
                <Text tagStyle='p2' className={styles['description']}>
                  {description}
                </Text>
                <LanguageLink to={link} colorScheme='blue'>
                  <Text tagStyle='p2'>
                    {t('Go to')} {title} {t('section')}
                  </Text>
                </LanguageLink>
              </div>
              <div className='d-none d-md-block w-100'>
                <ResponsiveImg src={image.link} alt={title} width={image.width} height={image.height} />
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default CategoriesSection;
