import React, {FC} from 'react';
import {Container, Row, Col} from '@inperium-corp/core';
import {ResponsiveImg, Text} from '@inperium-corp/gatsby-core';

import styles from './HeaderSection.scss';

interface IProps {
  title: string;
  subtitle: string;
}
const HeaderSection: FC<IProps> = ({title, subtitle}) => {
  return (
    <section className={styles['header-section']}>
      <Container>
        <Row>
          <Col xs={12} md={5} className='d-flex flex-column justify-content-center'>
            <Text tagStyle='h3'>{title}</Text>
            <Text tagStyle='p-md'>{subtitle}</Text>
          </Col>
          <Col md={6} className='d-none d-md-flex offset-md-1 p-0'>
            <ResponsiveImg src='/images/home/header.png' alt={title} height={240} width={480} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeaderSection;
