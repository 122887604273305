import React, {FC} from 'react';
import {Container, Row, Col} from '@inperium-corp/core';
import {LanguageLink, Text} from '@inperium-corp/gatsby-core';
import {Icon} from '@inperium-corp/icons';

import {useTranslation} from '@/core/i18n/useTranslation';
import {IArticleNode} from '@/core/dtos/IArticle';
import {groupArticles, sortGroups, getGroupName, sortArticles, getArticleLink, getGroupUrl} from '@/core/utils/articleUtils';

import styles from './ProductsSection.scss';

interface IProduct {
  icon: string;
  name: string;
  description: string;
  link: string;
  articleGroups: {
    title: string;
    link: string;
    articles: {
      title: string;
      link: string;
    }[];
  }[];
}

interface IProps {
  sellArticles: IArticleNode[];
  talkArticles: IArticleNode[];
}

const ProductsSection: FC<IProps> = ({sellArticles, talkArticles}) => {
  const productArticles = {
    Sell: groupArticles(sellArticles),
    Talk: groupArticles(talkArticles)
  };
  const {t} = useTranslation('home');
  const productsList: IProduct[] = t('products-section.products-list');
  return (
    <section className={styles['products-section']}>
      <Container className={styles['products-section__header']}>
        <Row>
          <Col className='p-0'>
            <Text tagStyle='h4'>{t('products-section.title')}</Text>
            <Text tagStyle='p'>{t('products-section.subtitle')}</Text>
          </Col>
        </Row>
      </Container>
      {productsList.map(({icon, name, description, link}) => {
        const articleGroups = sortGroups(Object.keys(productArticles[name]));
        return (
          <div key={name} className={styles['product']}>
            <Container className='p-0'>
              <Row>
                <Col>
                  <div className={styles['product__header']}>
                    <img src={icon} alt={name} />
                    <div className='d-flex flex-column'>
                      <Text tagStyle='caps-big'>{name}</Text>
                      <Text tagStyle='p3'>{description}</Text>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className={styles['product__groups']}>
                <Row>
                  {articleGroups.map((group, index) => (
                    <Col key={group} md={4} className={index > 1 ? 'd-none d-md-block' : 'd-block'}>
                      <div className={styles['product__groups-header']}>
                        <LanguageLink to={`${link}/${getGroupUrl(group)}`}>{getGroupName(group)}</LanguageLink>
                        <Icon icon='caretRight' />
                      </div>
                      <div className={styles['product__groups-list']}>
                        {productArticles[name][group]
                          .sort(sortArticles)
                          .slice(0, 3)
                          .map(({frontmatter: {title, type, group, url}}: IArticleNode) => (
                            <LanguageLink key={title} colorScheme='blue' to={getArticleLink({type, group, url})}>
                              <Text tagStyle='p2'>{title}</Text>
                            </LanguageLink>
                          ))}
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </Container>
            <LanguageLink className={styles['product__view-all']} to={link} colorScheme='blue'>
              <Text tagStyle='p2'>{t('products-section.view-all')}</Text>
            </LanguageLink>
          </div>
        );
      })}
    </section>
  );
};

export default ProductsSection;
